import * as PIXI from "pixi.js";
import { DropletManager } from "./DropletManager";

/**
 * Raindrop fragment shader, being used by PIXI.js in the EffectCanvas object
 * {{uniforms: {time: {type: string, value: number}, iResolution: {type: string, value: [*]}}, fragment: string}}
 */
const shaderData = {
  uniforms: {
    iResolution: {
      type: "v2",
      value: [window.innerWidth, window.innerHeight],
    },
    vTextureSize: {
      type: "v2",
      value: [0, 0],
    },
    uTextureForeground: {
      type: "sampler2D",
      value: null,
    },
    uTextureBackground: {
      type: "sampler2D",
      value: null,
    },
    uTextureDropShine: {
      type: "sampler2D",
      value: null,
    },
  },

  fragment: `
        precision mediump float;
    
        //Textures
        uniform sampler2D uTextureForeground;
        uniform sampler2D uTextureBackground;
        uniform sampler2D uTextureDropShine;
        
        //Canvas image data
        uniform sampler2D uSampler;
    
        //The resolution and coordinates of the current pixel
        uniform vec2 iResolution;
        uniform vec2 vTextureSize;
        varying vec2 vTextureCoord;
        
        //Function to get the vec2 value of the current coordinate
        vec2 texCoord(){
            return vec2(gl_FragCoord.x, iResolution.y - gl_FragCoord.y) / iResolution;
        }

        //Scales the bg up and proportionally to fill the container
        vec2 scaledTextureCoordinate(){
            float ratioCanvas = iResolution.x / iResolution.y;
            float ratioImage = vTextureSize.x / vTextureSize.y;
            
            vec2 scale = vec2(1, 1);
            vec2 offset = vec2(0, 0);
            float ratioDelta = ratioCanvas - ratioImage;

            if(ratioDelta >= 0.0){
                scale.y = (1.0 + ratioDelta);
                offset.y = ratioDelta / 2.0;
            }else{
                scale.x = (1.0 - ratioDelta);
                offset.x = -(ratioDelta / 2.0);
            }

            return (texCoord() + offset) / scale;
        }
        
        //Alpha-blends two colors
        vec4 blend(vec4 bg, vec4 fg){
            vec3 bgm = bg.rgb * bg.a;
            vec3 fgm = fg.rgb * fg.a;
            float ia = 1.0 - fg.a;
            float a = (fg.a + bg.a * ia);
            
            vec3 rgb;
            
            if(a != 0.0){
                rgb = (fgm + bgm * ia) / a;
            }else{
                rgb = vec3(0.0,0.0,0.0);
            }
            
            return vec4(rgb,a);
        }
        
        vec2 pixel(){
            return vec2(1.0, 1.0) / iResolution;
        }
        
        //Get color from fg
        vec4 fgColor(){
            return texture2D(uSampler, vTextureCoord);
        }
                
        void main(){
            vec4 bg = texture2D(uTextureBackground, scaledTextureCoordinate());
            vec4 cur = fgColor();

            float d = cur.b; // "thickness"
            float x = cur.g;
            float y = cur.r;
            float a = smoothstep(0.65, 0.7, cur.a);
            
            vec4 smoothstepped = vec4(y, x, d, a);

            vec2 refraction = (vec2(x, y) - 0.5) * 2.0;
            vec2 refractionPos = scaledTextureCoordinate() + (pixel() * refraction * (256.0 + (d * 512.0)));
            vec4 tex = texture2D(uTextureForeground, refractionPos);
            
            float maxShine = 390.0;
            float minShine = maxShine * 0.18;
            vec2 shinePos = vec2(0.5, 0.5) + ((1.0 / 512.0) * refraction) * -(minShine + ((maxShine-minShine) * d));
            vec4 shine = texture2D(uTextureDropShine, shinePos);
            tex = blend(tex,shine);
            
            vec4 fg = vec4(tex.rgb, a);
            gl_FragColor = blend(bg, fg);
        }
	`,
};

/**
 * EffectCanvas Class
 */
export class EffectCanvas {
  /**
   * EffectCanvas constructor
   */
  constructor(width, height, loader) {
    // Create and configure the renderer
    this.renderer = new PIXI.autoDetectRenderer(width, height, {
      antialias: false,
      transparent: false,
    });
    this.renderer.autoResize = true;
    document.body.appendChild(this.renderer.view);

    // Create a container object called the `stage`
    this.stage = new PIXI.Container();

    // Create a graphics object that is as big as the scene of the users window
    // Else the shader won't fill the entire screen
    this.background = new PIXI.Graphics();
    this.background.fillAlphanumber = 0;
    this.background.beginFill("0xffffff");
    this.background.drawRect(0, 0, width, height);
    this.background.endFill();
    this.background.alpha = 0;
    this.stage.addChild(this.background);

    // Create the DropletManager and pass it the stage so it can insert the droplet containers into it
    this.dropletManager = new DropletManager(this.stage, loader);

    // Send information about the textures and the size of the background texture through the uniforms to the shader
    shaderData.uniforms.uTextureDropShine.value =
      loader.resources["shine.png"].texture;
    shaderData.uniforms.uTextureBackground.value =
      loader.resources["background"].texture;
    shaderData.uniforms.uTextureForeground.value =
      loader.resources["foreground"].texture;
    shaderData.uniforms.vTextureSize.value = [
      loader.resources["background"].texture.width,
      loader.resources["background"].texture.height,
    ];

    // Create our Pixi filter using our custom shader code
    this.dropletShader = new PIXI.Filter(
      "",
      shaderData.fragment,
      shaderData.uniforms
    );

    // Apply it to our object
    this.stage.filters = [this.dropletShader];
  }

  /**
   * Simple resize function which redraws our graphics object that should fill the entire screen
   * @return {void}
   */
  resize(width, height) {
    this.renderer.resize(width, height);

    this.background.clear();
    this.background.beginFill("0xffffff");
    this.background.drawRect(0, 0, width, height);
    this.background.endFill();
  }

  /**
   * Updates the application and every child of the application
   * @return {void}
   */
  update(width, height) {
    this.updateShader(width, height);
    this.dropletManager.update(width, height);
  }

  /**
   * Updates the uniform values in the shader
   * @return {void}
   */
  updateShader(width, height) {
    this.dropletShader.uniforms.iResolution = [width, height];
  }

  /**
   * Renders the application and every child of the application
   * @return {void}
   */
  render() {
    this.renderer.render(this.stage);
  }
}
