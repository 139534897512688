import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const blogPostsMetadata = [
  {
    slug: "Web-Performance-Fundamentals-v1-Frontend-Masters",
    title: "Web Performance Fundamentals v1 - Frontend Masters",
    date: "9/14/2024",
    tags: ["performance", "Frontend Masters", "javascript"],
  },
];

const BlogList = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    setPosts(blogPostsMetadata);
  }, []);

  return (
    <div className="blog-list">
      <h2 className="blog-title">Latest</h2>
      <ul>
        {posts.map((post) => (
          <li className="blog-list-item" key={post.slug}>
            <Link className="blog-list-link" to={`/blog/${post.slug}`}>
              {post.title}
            </Link>
            {post.tags.map((tag, index) => {
              return (
                <span key={index} className="blog-tags">
                  {tag}
                </span>
              );
            })}
            <p className="blog-date">{post.date}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default BlogList;
