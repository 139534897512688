import React from "react";
import Main from "./Main";
import Canvas from "./Canvas";
import App from "./App";
import routes from "./routes";

const config = {
  width: 0,
  height: 0,
  antialias: true,
};

const Root = () => (
  <>
    <Main routes={routes} />
    <Canvas init={App} {...config} />
  </>
);

export default Root;
